<template>
    <div class="box">

        <!-- 租箱预售 BoxRentalAndPre_Sale-->
        <BoxRentalAndPre_Component></BoxRentalAndPre_Component>
        <Footer_Compontent></Footer_Compontent>
    </div>
</template>
<!-- EnquiryCabinet -->
<script>
import BoxRentalAndPre_Component from '../components/BoxRentalAndPre_Sale/BoxRentalAndPre_Component.vue';
import Footer_Compontent from "../components/Footer_Compontent.vue";
export default {
    name: "BoxRentalAndPre_Sale",
    components: { Footer_Compontent, BoxRentalAndPre_Component,  },
    data() {
        return {

        }
    },
}
</script>

<style lang="scss" scoped>
.box {
    height: 100%;
}
</style>