<template>
    <div>
        <div class="server_Box">
            <div class="server_Content">
                <!-- 出发站 -->
                <div class="selectS">
                    <el-select v-model="departureStationValue" placeholder="出发站" v-el-select-loadmore="loadmore"
                        filterable :filter-method="remoteMethod" @focus="changeFous">
                        <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <!-- 目的地 -->
                <div class="selectS">
                    <el-select v-model="destinationValue" placeholder="目的地" v-el-select-loadmore="loadmore" filterable
                        :filter-method="remoteMethod1" @focus="changeFous">
                        <el-option v-for="(item, index) in destinationListList" :key="index" :label="item.city"
                            :value="item.city">
                        </el-option>
                    </el-select>
                </div>
                <!-- 选择箱型 -->
                <div class="selectS">
                    <el-select v-model="selectBoxTypeValue" placeholder="选择箱型">
                        <el-option v-for="item in selectBoxType" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- 选择期望最早提箱日期  selectDate-->
                <div class="expectedDate">
                    <el-date-picker v-model="selectDate" type="date" value-format="yyyy-MM-dd" placeholder="选择期望最早提箱日期"
                        @change="changeDate($event)">
                    </el-date-picker>
                </div>
                <!-- 搜索  -->
                <div class="server_Botton" @click="handlePresale">
                    <p>搜索</p>
                </div>
            </div>
        </div>

        <div class="line_Box">
            <div class="line_List" v-show="presaleList">

                <ul>
                    <li v-for="(item, index) in presaleList" :key="index">
                        <div class="line_List_Left">
                            <div class="line_City">
                                <div>
                                    <span>{{ item.departure }}</span>
                                    <span>提箱城市</span>
                                </div>
                                <div>
                                    <img src="../../assets/homePage/botten-Y.png" alt="" width="24px">
                                </div>
                                <div>
                                    <span>{{ item.destination }}</span>
                                    <span>还箱城市</span>
                                </div>
                            </div>
                            <div class="atMost">
                                <span>最多可订：</span>
                                <span>{{ item.expList[0].maxBuy || 50 }}柜</span>
                            </div>

                        </div>
                        <div class="line_List_Center">
                            <span>柜型：</span>
                            <span>{{ item.cupboard }}</span>
                        </div>
                        <div class="line_List_Right">
                            <div class="line_List_Price">
                                <p>
                                    <span>{{ item.expList[0].currency }} {{ item.expList[0].price }}</span>
                                    <span>/{{ item.period }}日</span>
                                </p>
                                <p>
                                    <span>超期费用</span>
                                    <span>{{ item.expList[0].currency }} &nbsp; {{ item.expList[0].expPrice }}</span>
                                </p>
                            </div>
                            <div class="placeAnOrderImmediately" @click=immediately(item.id,item.boxType)>
                                <span>立即下单</span>
                            </div>
                        </div>
                    </li>

                </ul>

            </div>
            <div class="imgS" v-show="presaleList == ''">
                <img src="../../assets/myHomePage/Empty state.png" alt="">
                <span>暂无数据</span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { homecityList, presaleList } from '../../tools/ajax'
import { baseUrl } from '../../tools/baseUrl';
import { mapState } from 'vuex';
export default {
    name: "BoxRentalAndPre_Component",
    data() {
        return {
            selectDate: "",//选择日期
            departureStation: '',//出发站
            departureStationValue: "",  //出发站值
            departureStationList: [],  //出发站数据
            destinationListList: [], //目的站
            destinationValue: "", //目的站值
            selectBoxTypeValue: "", //选择箱型值
            selectBoxType: [{  //选择箱型
                value: '20GP',
                label: '20GP'
            }, {
                value: '40GP',
                label: '40GP'
            }, {
                value: '40HQ',
                label: '40HQ'
            }, {
                value: '45HQ',
                label: '45HQ'
            }, {
                value: '20OT',
                label: '20OT'
            },
            {
                value: '40OT',
                label: '40OT'
            },
            {
                value: '20NOR',
                label: '20NOR'
            }, {
                value: '40NOR',
                label: '40NOR'
            },
            {
                value: '20FR',
                label: '20FR'
            },
            {
                value: '40FR',
                label: '40FR'
            },
            {
                value: '20ISO',
                label: '20ISO'
            },],
            formData: {
                pageNo: 1, //当前页
            },
            presaleList: [], //租箱预售数据
            authInfo: "",
        }
    },
    watch: {
        ...mapState(['token'])
    },
    created() {
        var token = sessionStorage.getItem("token")
        axios.get(`${baseUrl}/box/queryById`, {
            params: { id: "1586336389949345794" },
            headers: { 'authorization': token }
        })
            .then(res => {
                // console.log(res)
            })
            .catch(err => {
                console.error(err);
            })

        var token = sessionStorage.getItem("token")
        homecityList({ pageNo: '1' }).then((res) => {
            this.departureStationList = res.result.records;
            this.destinationListList = res.result.records;
        });

        presaleList({
            boxType: "预售",

        }, {
            headers: {
                'authorization': token
            }
        }).then(res => {
            // console.log("%", res.result.records);
            this.presaleList = res.result.records;

        });

        this.getauthenView()

    },
    methods: {
        getauthenView() {  //认证信息
            var token = sessionStorage.getItem("token");
            if (token) {
                axios.get(`${baseUrl}/authen/view`, {
                    headers: {
                        'authorization': token
                    }
                }).then(res => {
                    if (res.data.success == true) {
                        this.authInfo = res.data.result.status;
                        // console.log(this.authInfo);
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                        return false
                    }
                })
            }

        },

        changeFous() {
            this.formData.pageNo = 1;
            homecityList().then(res => {
                console.log(res.result);
                this.departureStationList = res.result.records;
                this.destinationListList = res.result.records;
            });
        },
        remoteMethod(value) { //
            console.log("$$$", value);
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        console.log("---", res.data.result.records);
                        this.departureStationList = res.data.result.records;
                        // this.destinationListList = res.data.result.records;
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    console.log(res.result);
                    this.departureStationList = res.result.records;
                    // this.destinationListList = res.result.records;
                });
            }

        },
        remoteMethod1(value) {
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        console.log("---", res.data.result.records);
                        // this.departureStationList = res.data.result.records;
                        this.destinationListList = res.data.result.records;
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    console.log(res.result);
                    // this.departureStationList = res.result.records;
                    this.destinationListList = res.result.records
                });
            }
        },


        changeDate(e) { //选择的日期
            // console.log("%%", e);
        },
        handlePresale() { //预售搜索 
            presaleList({
                boxType: "预售",
                departure: this.departureStationValue,
                destination: this.destinationValue,
                cupboard: this.selectBoxTypeValue
            }).then(res => {
                // console.log("%", res.result.records);
                this.presaleList = res.result.records;
                // console.log(this.presaleList == '');
            })
        },
        immediately(id, boxType) {
            // console.log(index);
            let uesrInfo = sessionStorage.getItem("token");
            let authInfo = JSON.parse(localStorage.getItem("authInfo"));
            console.log(authInfo);

            if (uesrInfo == '' || uesrInfo == undefined || uesrInfo == null || !this.token == undefined || !this.token == '') {
                this.$message({
                    showClose: true,
                    message: '您还没有登录',
                    type: 'error'
                });
                return false
            } else if (this.authInfo !== 'Y') {
                // console.log("未认证");
                this.$message({
                    showClose: true,
                    message: '您还没有认证，请先去认证',
                    type: 'error',
                });
                return false
            } else if (this.token || uesrInfo) {
                this.$router.push({
                    path: "/Inquiry",
                    query: {
                        id: id,
                        boxType: boxType
                    }
                }
                )
            }

            // if (this.token || uesrInfo) {
            //     this.$router.push({
            //         path: "/Inquiry",
            //         query: {
            //             id: id,
            //             boxType: boxType
            //         }
            //     }
            //     )
            // } else {
            //     this.$message.error('您还没有登录,请先登录');
            // }
        },
        // #region //搜索框
        // 懒加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        // 下拉刷新
        getList(formData) {
            homecityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.departureStationList = [...this.departureStationList, ..._res];
                this.destinationListList = [...this.destinationListList, ..._res];
            })
        }
        // #endregion
    },
}
</script>

<style lang="scss" scoped>
.server_Box {
    width: 100%;
    height: 158px;
    background: #F6F6F6;

    .server_Content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1200px;
        height: 100%;
        margin: 0 auto;

        .selectS {
            width: 244px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;

            .el-select {
                width: 100%;
                height: 100%;

                ::v-deep .el-input {
                    width: 100%;
                    height: 100%;

                    .el-input__inner {
                        width: 100%;
                        height: 100%;
                    }
                }

            }
        }

        // 出发站
        .departureStation {
            width: 244px;
            height: 56px;
            // border: 1px solid #2B2B2B;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            line-height: 56px;
        }

        // 目的地
        .destination {
            width: 244px;
            height: 56px;
            // border: 1px solid #2B2B2B;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            line-height: 56px;
        }

        // 选择箱型
        .selectBoxType {
            width: 244px;
            height: 56px;
            // border: 1px solid #2B2B2B;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            line-height: 56px;
        }

        // 期望日期
        .expectedDate {
            position: relative;
            width: 244px;
            height: 56px;
            // border: 1px solid #2B2B2B;
            background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            line-height: 56px;

            ::v-deep .today {
                span {
                    font-weight: normal;
                    color: red;
                }

            }

            .el-date-editor {
                width: 244px;
                height: 56px;

                ::v-deep .el-input__inner {
                    width: 100%;
                    height: 100%;
                    // border: 0;
                    background-color: #FFFFFF;
                }

            }

            // .el-input__inner {}

        }

        .server_Botton {
            width: 160px;
            height: 56px;
            background: #1890FF;
            box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 56px;
            cursor: pointer;
        }
    }
}

.line_Box {
    width: 1200px;
    margin: 24px auto;

    .imgS {
        width: 150px;
        height: 300px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;

        img {
            display: block;
            width: 150px;
            height: 104px;
            // margin: auto;    
        }

        span {
            text-align: center;
        }
    }

    .line_List {
        width: 100%;
        height: 100%;



        ul {
            li {
                display: flex;
                // justify-content: space-between;
                width: 1200px;
                height: 120px;
                background: #FFFFFF;
                box-shadow: 0px 2px 2px 1px rgba(51, 51, 51, 0.3);
                border-radius: 8px 8px 8px 8px;
                opacity: 1;
                border: 1px solid #999999;
                margin-top: 32px;

                .line_List_Left {
                    width: 352px;

                    padding-top: 32px;

                    // 城市 
                    .line_City {
                        width: 410px;
                        height: 18px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-left: 54px;
                        // margin-top: 22px;


                        div {
                            span:nth-child(1) {
                                font-size: 18px;
                                line-height: 18px;
                                font-weight: 400;
                                color: #1890FF;
                                // margin-left: 54px;
                            }

                            span:nth-child(2) {
                                font-size: 16px;
                                line-height: 16px;
                                color: #333333;
                                font-weight: 400;
                                margin-left: 4px;
                            }
                        }

                        div:nth-child(2) {
                            width: 26px;
                            height: 12px;
                            line-height: 18px;
                            // background: linear-gradient(180deg, #90C9FF 0%, #1890FF 100%);
                        }
                    }

                    //最多可订
                    .atMost {
                        padding-left: 54px;
                        margin-top: 24px;
                        height: 14px;

                        span:nth-child(1) {
                            font-size: 14px;
                            font-family: '黑体';
                            font-weight: 400;
                            color: #333333;
                            line-height: 14px;
                        }

                        span:nth-child(2) {
                            font-weight: 400;
                            color: #999999;
                            line-height: 14px;
                            font-size: 14px;
                        }
                    }
                }

                .line_List_Center {
                    width: 477px;
                    text-align: center;

                    span {
                        display: inline-block;
                        margin-top: 72px;
                    }

                    span:nth-child(1) {
                        font-size: 14px;
                        color: #333333;
                    }

                    span:nth-child(2) {
                        font-size: 14px;
                        color: #999999;
                        font-weight: 400;
                    }
                }

                .line_List_Right {
                    display: flex;
                    justify-content: space-between;
                    // align-items: center;

                    p:nth-child(1) {
                        line-height: 18px;
                        margin-top: 32px;

                        span:nth-child(1) {
                            font-size: 18px;
                            color: #1890FF;
                        }

                        span:nth-child(2) {
                            font-size: 18px;
                            color: #333333;
                            margin-left: 8px;
                        }
                    }

                    p:nth-child(2) {
                        line-height: 14px;
                        margin-top: 10px;

                        span:nth-child(1) {
                            font-size: 14px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #333333
                        }

                        span:nth-child(2) {
                            font-size: 14px;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #1890FF;
                            line-height: 24px;
                            margin-left: 8px;
                        }
                    }
                }

                .placeAnOrderImmediately {
                    width: 120px;
                    height: 40px;
                    background: #FFC13B;
                    box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
                    border-radius: 4px 4px 4px 4px;
                    text-align: center;
                    line-height: 40px;
                    margin-top: 27px;
                    margin-left: 43px;
                    cursor: pointer;

                }
            }

            li:nth-child(1) {
                margin-top: 0;
            }
        }
    }
}
</style>